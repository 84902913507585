import { gql } from '@apollo/client';

export const SET_MODENA_PAYMENT_METHOD = gql`
  mutation SetModenaPaymentMethod($cartId: String!, $paymentMethod: String!) {
    setPaymentMethodOnCart(
      input: { cart_id: $cartId, payment_method: { code: $paymentMethod } }
    ) {
      cart {
        selected_payment_method {
          code
          title
        }
      }
    }
  }
`;